import React from 'react';
import * as Icon from 'react-feather';

import Team1 from '../../assets/images/team-image/team1.jpg'
import Team2 from '../../assets/images/team-image/team2.jpg'
import Team3 from '../../assets/images/team-image/team3.jpg'
import Team4 from '../../assets/images/team-image/team4.jpg'
import Team5 from '../../assets/images/team-image/team5.jpg'
import Team6 from '../../assets/images/team-image/team6.jpg'
import Team7 from '../../assets/images/team-image/team7.jpg'
import Team8 from '../../assets/images/team-image/team8.jpg'
import Team9 from '../../assets/images/team-image/team9.jpg'
import Team10 from '../../assets/images/team-image/team10.jpg'




import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    items: 5,
    loop: true,
    nav: false,
    dots: true,
    margin: 30,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2
        },
        900: {
            items: 3
        },
        1200: {
            items: 4
        },
        1500: {
            items: 5
        }
    }
}

const Team = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="team-area ptb-80 bg-f9f6f6">
            <div className="container">
                <div className="section-title">
                    <h2>Ekibimiz</h2>
                    <div className="bar"></div>
                    <p>Ortamda bulunmaktan, beraber proje geliştirmekten keyf aldığımız, desteklerini esirgemeyen kendi alanında uzman ekibimiz </p>
                </div>
            </div>

            {display ? <OwlCarousel 
                className="team-slider owl-carousel owl-theme owl-theme-style"
                {...options}
            > 
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team1} alt="yazılım uzmanı" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Tahsin PİCAK</h3>
                            <span>CEO & Founder</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face" href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter" href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                      
                        </ul>

                        <p>Proje analiz uzmanı, analitik düşünceleri ile kararlı sistem geliştiricisi</p>
                    </div>
                </div>
            
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team2} alt="takım lideri" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Emine YILDIRIM</h3>
                            <span>Team Leader</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face" href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter"   href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                       
                        </ul>

                        <p>Yeni sistem öğreniminde uzman, ekip yönetiminde lider</p>
                    </div>
                </div>
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team3} alt="proje geliştirici" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Rumeysa TAŞ</h3>
                            <span>Developer</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                    
                        </ul>

                        <p>Azimli, istekli sürekli öğrenmeye açık gelişmeye devam eden bir developer</p>
                    </div>
                </div>

                
            
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team4} alt="developer" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Merve KARAHAN</h3>
                            <span>Developer</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                       
                        </ul>

                        <p>Azimli, istekli öğrenmeye açık gelişmeye devam eden bir developer</p>
                    </div>
                </div>
        
                
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team5} alt="image processing developer" />
                    </div>
                    <div className="team-content">
                        <div className="team-info">
                            <h3>Hasan Hüseyin DOĞAN</h3>
                            <span>Image Processing Developer - Freelancer</span>
                        </div>
                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="linkedin"  href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                       
                        </ul>
                        <p>Görüntü işleme alanında kendini geliştirmiş</p>
                    </div>
                </div>

                <div className="single-team">
                    <div className="team-image">
                        <img src={Team6} alt="proje computer enginier" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Fatih GÜNAY</h3>
                            <span>Stajer Developer</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                     
                        </ul>

                        <p>3D çizimlerde matematiksel hareketlerin kodlanması</p>
                    </div>
                </div>
 
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team7} alt="mobil app developer" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Furkan MUTLU</h3>
                            <span>Mobil Developer - Freelancer</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="linkedin"  href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                          
                        </ul>

                        <p>Mobil projelerimize hız veren destekçimiz</p>
                    </div>
                </div>
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team8} alt="Freelancer developer" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Halit AKSOY</h3>
                            <span>Mobil Developer - Freelancer</span>
                        </div>
                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a  aria-label="linkedin"  href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                            
                        </ul>

                        <p>Araştırmacı aynı zamanda mobil projelerimizdeki yardımcımız</p>
                    </div>
                </div>
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team9} alt="UX/UI Designer" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Grafikerlerimiz</h3>
                            <span>UX/UI Designer</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                      
                        </ul>

                        <p>Projelerimize renk katan alanında uzman grafiker arkadaşlarımız </p>
                    </div>
                </div>
            
                <div className="single-team">
                    <div className="team-image">
                        <img src={Team10} alt="project partners" />
                    </div>

                    <div className="team-content">
                        <div className="team-info">
                            <h3>Proje Ortaklarımız</h3>
                            <span>Partners</span>
                        </div>

                        <ul>
                            <li>
                                <a aria-label="face"  href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                                    <Icon.Facebook />
                                </a>
                            </li>
                            <li>
                                <a aria-label="twitter"  href="https://twitter.com/" target="_blank" rel="noreferrer">
                                    <Icon.Twitter />
                                </a>
                            </li>
                            <li>
                                <a aria-label="linkedin"   href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                                    <Icon.Linkedin />
                                </a>
                            </li>
                       
                        </ul>

                        <p>Özel projelerde ortaklık kurduğumuz değerli dostlarımız</p>
                    </div>
                </div>
            </OwlCarousel> : ''}
        </div>
    )
}

export default Team;