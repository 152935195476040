import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Proje Üreticisi </h2>
                    <div className="bar"></div>
                    <p>Yazılım ihtiyaçlarınızı doğru analiz edip taleplerinizi kodlamak ve çalıştığını görmek, hatta firmanızda vazgeçilmez olacak derecede bir proje üretmek en büyük enerji kaynağımızdır</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>15+</h3>
                            <p>Yıllık Tecrübe</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>%100</h3>
                            <p>Güvenilirlik</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>30+</h3>
                            <p>Proje</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>1000+</h3>
                            <p>Contact</p>
                        </div>
                    </div>
                </div>

                <div className="contact-cta-box">
                    <h3>Hakkımızda Sorularınız Var Mı?</h3>
                    <p>Bizimle iletişime geçmekten çekinmeyin</p>

                    <Link to="/contact" className="btn btn-primary">
                        İletişim
                    </Link>
                </div>

                <div className="map-bg">
                    <img src={MapImg} alt="proje fikirleri yazılım süreçleri yönetim app mobil uygulama" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;